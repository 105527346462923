import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import "../styles/podcasts.scss"
const Podcast = ({ data }) => (
  <Layout seoMetaTags={data?.datoCmsPodcastspage?.seoMetaTags} page="Podcastit">
    <div className="podcasts">
      <div className="content">
        <div
          dangerouslySetInnerHTML={{
            __html: data.datoCmsPodcastspage.bodyNode.childMarkdownRemark.html,
          }}
        />
        <div>
          {data.datoCmsPodcastspage.podcasts.map(podcast => (
            <div>
              <h3>{podcast.title}</h3>
              {/**  eslint-disable-next-line */}
              <audio controls>
                <source src={podcast.asset.url} type="audio/mpeg"></source>
              </audio>
            </div>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)
export const query = graphql`
  query podcastsQuery {
    datoCmsPodcastspage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
      podcasts {
        title
        asset {
          url
        }
      }
    }
  }
`

export default Podcast
